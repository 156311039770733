<template>
  <div
    class="navbar-container d-flex content align-items-center bg-custom-primary rounded"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1"
      :style="currentBreakPoint === 'xs' ? 'max-width:130px' : ''"
      :class="{ 'text-truncate': truncateText }"
    >
      <span
        v-if="project.id"
        class="my-0 text-light h3 cursor-pointer"
        :class="{
          h3: !isSmallScreen,
          h6: isSmallScreen,
          'cursor-pointer': isSmallScreen,
        }"
      >
      <!-- Mostrar el nombre del proyecto si el rol es 'admin' -->
    <span v-if="currentUser.role_name === 'admin'">{{ project.name }}</span>

      <!-- Mostrar el componente <project-selector> para otros roles -->
      <project-selector
        v-else-if="validRoute"
        :size="'md'"
        :extra-class="'bg-primary text-light'"
        class="mx-1 mt-1"
      />

      </span>
    </div>

    <b-navbar-nav
      class="nav justify-content-between align-items-center ml-auto"
    >
      <div class="mr-2">
        <b-button
          size="sm"
          class="mr-1"
          :class="{ 'custom-padding': isSmallScreen }"
          variant="outline-light"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="15"
          />
        </b-button>
        <b-button
          size="sm"
          :class="{ 'custom-padding': isSmallScreen }"
          variant="outline-light"
          @click="$router.go(1)"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="15"
          />
        </b-button>
      </div>
      <!-- <project-selector v-if="validRoute" /> -->
      <b-nav-item-dropdown
        v-if="currentUser.name"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser.name }}
            </p>
            <span class="user-status">{{
              currentUser.role_name | roleName
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="`${currentUser.logo}`"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="
            $router.push({
              name: 'editCustomer',
              params: { customerId: currentUser.id },
            })
          "
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import projectSelector from '@/components/projects/ProjectSelector.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    projectSelector,

    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      truncateText: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['isSmallScreen', 'currentBreakPoint']),
    ...mapGetters('projects', ['projects', 'project']),

    validRoute() {
      if (this.currentUser.role_name === 'admin') return false
      if (this.$route.name === 'quotes') return true
      if (this.$route.name === 'groupedExpenses') return true
      if (this.$route.name === 'expenses') return true
      if (this.$route.name === 'incomes') return true
      if (this.$route.name === 'incomesVsExpenses') return true
      if (this.$route.name === 'expensesReport') return true
      if (this.$route.name === 'reports') return true
      if (this.$route.name === 'allExpenses') return true
      return false
    },
  },
  methods: {
    ...mapMutations('auth', ['clearCurrentUser']),
    logout() {
      document.documentElement.style.setProperty('--primary-custom', '#340668')
      document.documentElement.style.setProperty(
        '--secondary-custom',
        '#340668',
      )
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      this.clearCurrentUser()
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      // Reset ability
      // this.$ability.update(initialAbility)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 4px 4px !important;
}
</style>
